import {AccountsService} from "../../Http/Accounts/AccountsService";
import {WidgetsService} from "../../Http/Widgets/WidgetsService";
import template from "./accounts-details-non-hsa.html"

ko.components.register('accounts-details-non-hsa', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountsDetailsNonHsaModel(widgetInfo.updateHeight);
        }
    }
});



function AccountsDetailsNonHsaModel(updateHeight) {
    const self = this;
    $.cdh.extensions.profile.ProfileItemBase.call(self, updateHeight);

    let accountId = $.cdh.helper.urlParam('accountId');
    let options = $.cdh.helper.urlParam('options');
    let page = $.cdh.helper.urlParam('page');

    self.paramAccountId = accountId;
    self.paramOptions = options;

    self.isPreferences = page === 'Preferences';
    self.isSubmitClaim = page === 'SubmitClaim';

    self.accountId = accountId;
    self.options = options;
    self.updateHeight = updateHeight;

    self.accountsService = new AccountsService();

    self.dependents = ko.observableArray([]);

    self.callComputed = ko.observable();
    self.HasOnlyOneAccount = ko.observable(false);
    self.allowAddClaim = ko.observable($.cdh.employee.allowEnterClaimRequests());

    let $window = $(window);
    self.width = ko.observable($window.width());
    $window.resize(function () {
        self.width($window.width());
    });

    self.isMobileMode = ko.pureComputed(function () {
        return $.cdh.isMobile && self.width() < 768;
    });

    self.extendedDetails = new $.cdh.get.accounts.detailsNonHsa(accountId, options);
    self.dependentsRequest = new $.cdh.get.accounts.dependentsNonHsa(accountId, options);
    self.account = ko.observable(null);

    $(document.body).click((event) => {
        var $trigger = $('div.js-tooltip');
        var anyOpenContainer = $('div.tool_tip_data');
        if (!$trigger.is(event.target) && (anyOpenContainer.find(event.target)).length === 0 && anyOpenContainer.length !== 0) {
            anyOpenContainer.removeClass('data_opened');
        }
    })
    self.handleToolTipClick = (data, event) => {
        var $parent = $('.coverage_period_details');
        var anyOpenContainer = $parent.find('.tool_tip_data.data_opened');
        var $element = $(event.currentTarget);
        var optionsContainer = $element.find('.tool_tip_data');
        optionsContainer.removeClass('inverted');
        if ((anyOpenContainer.find(event.target)).length !== 0) {
            return;
        }
        optionsContainer.toggleClass('data_opened');
        if ($element[0].getBoundingClientRect().bottom + optionsContainer.height() > $(window).innerHeight()) {
            optionsContainer.addClass('inverted');
        }
        if (anyOpenContainer.length !== 0) {
            anyOpenContainer.removeClass('data_opened');
        }
    }
    self.IntervalTracker = new $.cdh.get.accounts.intervalTracker(accountId);
    //self.FieldsAliases = ko.observable();

    self.selectedInterval = ko.observableArray();
    self.IntervalTrackerData = ko.observableArray();

    self.planAutoPaymentPreferenceEnabled = ko.pureComputed(function () {
        if (!self.extendedDetails.hasData())
            return false;

        return self.extendedDetails.data().PlanAutoPaymentPreferenceEnabled;
    });

    self.planAutoReimbursementPreferenceEnabled = ko.pureComputed(function () {
        if (!self.extendedDetails.hasData())
            return false;

        return self.extendedDetails.data().PlanAutoReimbursementPreferenceEnabled;
    });

    self.showPreference = ko.pureComputed(function () {
        return (self.planAutoPaymentPreferenceEnabled() && $.cdh.employee.allowAutoPaymentPreference()) || (self.planAutoReimbursementPreferenceEnabled() && $.cdh.employee.allowAutoReimbursementPreference());
    });

    self.annualContributionChartData = ko.pureComputed(function () {
        if (!self.account() || !self.extendedDetails.hasData()) {
            return null;
        }

        return {
            AccountType: self.account().AccountType,
            ContributionsYTD: self.extendedDetails.data().ContributionsYTD,
            RemainingContributions: self.extendedDetails.data().RemainingContributions
        }
    });

    self.submitClaimBuilder = function () {
        let model = new $.cdh.models.SubmitClaimWidgetModel(self.updateHeight, self.updateHeight);
        model.forAccountId = accountId;
        model.title = $.cdh.languageConstant.getConstant('AccountDetail.lblSubmitClaim');
        return model;
    };

    self.preferenceModelBuilder = function () {
        return new $.cdh.models.ClaimsAutoPaymentPrefs(self);
    };

    self.isTypeClassFsaOrOther = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().AccountTypeClass === $.cdh.enums.accountTypeClass.fsa ||
            self.account().AccountTypeClass === $.cdh.enums.accountTypeClass.other;
    });

    self.isTypeClassFsa = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().AccountTypeClass === $.cdh.enums.accountTypeClass.fsa;
    });

    self.isTypeCra = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().IsCra;
    });

    self.extendedDetails.state.subscribe(updateHeight);

    self.anyDeps = ko.pureComputed(function () {
        if(self.account().HideFamilyDetails)
            return false;

        if (!self.extendedDetails.hasData())
            return false;

        return self.extendedDetails.data().AnyDependentAvailable;
    });

    self.planDescAvailable = ko.pureComputed(function () {
        return $.cdh.helper.isValueSet(self.account().PlanUsageDescription);
    });
    self.isPlanDescVisible = ko.observable(false);
    self.togglePlanDesc = function () {
        self.isPlanDescVisible(!self.isPlanDescVisible());
    };

    self.accountDetails = ko.observable();
    self.isHRX = ko.observable(false);
    self.extendedDetails.load().done(function (details) {
        if (details.AnyDependentAvailable) {
            self.dependentsRequest.load().done(function (dependents) {
                ko.utils.arrayPushAll(self.dependents, dependents);
                updateHeight();
            });
        }

        self.accountDetails(details);
        if (details.AccountType === "HRX") {
            self.isHRX(true);
        }
    });

    self.rewardsUrl = ko.observable();
    self.widgetsService = new WidgetsService();
    self.widgetsService.getRewardsUrlAsync().then(msg => {
        self.rewardsUrl(msg.message);
    });


    self.IntervalChanged = function () {

        let _account = {
            currentInvervalPeriod: ko.observable(self.selectedInterval().IntervalPeriod),
            currentIntervalBalance: ko.observable(self.selectedInterval().IntervalBalance),
            currentIntervalDeposits: ko.observable(self.selectedInterval().IntervalDeposits),
            currentIntervalDisbursed: ko.observable(self.selectedInterval().IntervalDisbursed),
            currentIntervalRemaining: ko.observable(self.selectedInterval().IntervalRemaining),
            currentSpendingLimit: ko.observable(self.selectedInterval().SpendingLimit)
        }

        if (self.account()) {
            $.extend(self.account(), _account)
            self.account(self.account());
            updateHeight();
        }
    }





    function loadAccount() {
        self.accountsService.countCurrentAsync().then(count => self.HasOnlyOneAccount(count === 1));
        var monthIntervalVal = '1';
        var quarterIntervalVal = '3';

        self.accountsService.byAccountIdAsync(self.accountId).then(account => {
            if (account) {
                self.allowAddClaim(account.AddClaimAllowed && account.ManualClaimsEnabled);
            }

            if (self.isSubmitClaim && self.allowAddClaim() && $.cdh.tpa.useExpensePortal) {
                document.location.href = $.cdh.routes.predefined.claims.portal;
            }

            account.IsMonthOrQuarter = ko.observable(false);
            account.IsMonthOrQuarter = (account.SpendingLimitPeriod == monthIntervalVal || account.SpendingLimitPeriod == quarterIntervalVal);

            return account;
        }).then(function (account) {

            account.accountIntervalType = ko.observable(false);
            account.PayrollHeader = ko.observable("Payroll Deposits YTD");
            account.currentInvervalPeriod = ko.observable(null);
            account.currentIntervalBalance = ko.observable(null);
            account.currentIntervalDeposits = ko.observable(null);
            account.currentIntervalDisbursed = ko.observable(null);
            account.currentIntervalRemaining = ko.observable(null);
            account.currentSpendingLimit = ko.observable(null);
            if (account.IsMonthOrQuarter) {
                self.IntervalTracker.load().done(function (data) {
                    self.IntervalTrackerData(data);
                    if (data.length) {
                        if (account.AccountPlanYear === $.cdh.enums.planYear.current || account.AccountPlanYear === $.cdh.enums.planYear.previous) {
                            if (data[data.length - 1].IntervalType == monthIntervalVal || data[data.length - 1].IntervalType == quarterIntervalVal) {
                                account.currentInvervalPeriod(data[data.length - 1].IntervalPeriod);
                                account.currentIntervalBalance(data[data.length - 1].IntervalBalance);
                                account.currentIntervalDeposits(data[data.length - 1].IntervalDeposits);
                                account.currentIntervalDisbursed(data[data.length - 1].IntervalDisbursed);
                                account.currentIntervalRemaining(data[data.length - 1].IntervalRemaining);
                                account.currentSpendingLimit(data[data.length - 1].SpendingLimit);

                                if (data[data.length - 1].IntervalType == monthIntervalVal) {
                                    account.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderMonthly'));
                                }
                                else if (data[data.length - 1].IntervalType == quarterIntervalVal) {
                                    account.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderQuarterly'));
                                }
                                account.accountIntervalType(true);
                            }
                        }
                        /* The below code for future plans is for this below requirement:
                         * User Story 223044: [Monthly/quarterly]Payroll Deposit YTD Showing instead of MTD in Account Details page
                         * Given: I am a WCP user
                         * When: I am on Account Details screen
                         * Then: Pay roll MTD Should display in both summery & account details page 
                         * 
                         * Since the interval tracker details for Future DCA/FSN plans are not being sent in API response, validation for below code is not yet done.
                         * This needs to be validated once the WCA response has interval tracker details for future DCA/FSN plans
                         * */
                        else if (account.AccountPlanYear === $.cdh.enums.planYear.future) {
                            if (data[0].IntervalType == monthIntervalVal || data[0].IntervalType == quarterIntervalVal) {

                                account.currentInvervalPeriod(data[0].IntervalPeriod);
                                account.currentIntervalBalance(data[0].IntervalBalance);
                                account.currentIntervalDeposits(data[0].IntervalDeposits);
                                account.currentIntervalDisbursed(data[0].IntervalDisbursed);
                                account.currentIntervalRemaining(data[0].IntervalRemaining);
                                account.currentSpendingLimit(data[0].SpendingLimit);

                                if (data[0].IntervalType == monthIntervalVal) {
                                    account.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderMonthly'));
                                }
                                else if (data[0].IntervalType == quarterIntervalVal) {
                                    account.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderQuarterly'));
                                }
                                account.accountIntervalType(true);
                            }
                        }
                    }
                });
            }
            self.account(account);
            updateHeight();
        });
    }



    loadAccount();

    self.updateDeductibleStatusCallback = function () {
        self.account(null);
        self.accountsService.clearCache();
        loadAccount();
    };

    self.displayAnnualElection = ko.pureComputed(function () {
        if (!self.account() || !self.extendedDetails.hasData())
            return false;

        if (self.account().HidePayrollInfoAndCharts || !self.extendedDetails.data().ShowDeadlines)
            return false;

        return true;
    });

    self.isExistActiveCarryover = ko.pureComputed(function () {
        return self.account() && self.account().ActiveCarryoverSettings;
    });

    self.sourceCarryoverAmountLabel = ko.pureComputed(function () {
        if (self.isExistActiveCarryover()) {
            return self.account().ActiveCarryoverSettings.AmountCustomLabel;
        }

        return null;
    });

    self.showDeadlines = ko.pureComputed(() => {
        if (!self.account() || !self.extendedDetails.hasData())
            return false;

        let fieldsAliases = self.account().FieldsAliases;
        return self.extendedDetails.data().ShowDeadlines &&
            (self.extendedDetails.data().ShowEligibilityDate || fieldsAliases.PlanStartDate || fieldsAliases.PlanEndDate ||
                fieldsAliases.LastDayForSpending || fieldsAliases.LastDayToSubmitClaims);
    });

    self.rolloverAmountDescription = function (rollover) {
        let details = self.extendedDetails.data();

        let appendRolloverInfo = function (rolloverInfo, result) {
            for (let i = 0; i < rolloverInfo.length; i++) {
                let info = rolloverInfo[i];

                let individualAmount = rollover.IsPrimary
                    ? info.PrimaryFundRolloverAmount
                    : info.SecondaryFundRolloverAmount;

                let individualRate = rollover.IsPrimary
                    ? info.PrimaryFundRolloverRate
                    : info.SecondaryFundRolloverRate;

                if (result) {
                    result = result + "<br/>" +
                        self.getFundRolloverDescription(individualAmount, individualRate, rollover.RolloverRule);
                } else {
                    result = self.getFundRolloverDescription(individualAmount, individualRate, rollover.RolloverRule);
                }
            }

            return result;
        };

        let result = "";

        if (details.IsHRA && details.FundRolloverInfo) {
            result = $.cdh.languageConstant.getConstant('AccountDetail.lblFamilyAmt')+":";

            if (rollover.RolloverRule != $.cdh.enums.fundRolloverTypeEnum.allFunds) {
                result = appendRolloverInfo(details.FundRolloverInfo, result);
            } else {
                result = result + "<br/>" + $.cdh.languageConstant.getConstant('AccountDetail.msgRollOverDescription1');
            }

            result = result + "<br/><br/>" + $.cdh.languageConstant.getConstant('AccountDetail.lblIndividualAmt')+":<br/>";
            result = result +
                self.getFundRolloverDescription(
                    rollover.IndividualRolloverAmount,
                    rollover.IndividualRolloverRate,
                    rollover.RolloverRule);

        } else if (!details.IsHRA && details.FundRolloverInfo) {

            if (rollover.RolloverRule != $.cdh.enums.fundRolloverTypeEnum.allFunds) {
                result = appendRolloverInfo(details.FundRolloverInfo, result);
            } else {
                result = result + $.cdh.languageConstant.getConstant('AccountDetail.msgRollOverDescription1');
            }

        } else if (details.IsHRA) {

            result = result + $.cdh.languageConstant.getConstant('AccountDetail.lblFamilyAmt') + ":<br/>";
            result = result +
                self.getFundRolloverDescription(
                    rollover.RolloverAmount,
                    rollover.RolloverRate,
                    rollover.RolloverRule) + "<br/><br/> " + $.cdh.languageConstant.getConstant('AccountDetail.lblIndividualAmt') +": <br/>";

            result = result +
                self.getFundRolloverDescription(
                    rollover.IndividualRolloverAmount,
                    rollover.IndividualRolloverRate,
                    rollover.RolloverRule);

        } else {
            result = self.getFundRolloverDescription(
                rollover.RolloverAmount,
                rollover.RolloverRate,
                rollover.RolloverRate);
        }

        return result;
    };

    self.getFundRolloverDescription = function (amount, rate, type) {
        let result = "";

        switch (type) {
            case $.cdh.enums.fundRolloverTypeEnum.allFunds:
            case $.cdh.enums.fundRolloverTypeEnum.none: {
                result = $.cdh.languageConstant.getConstant('AccountDetail.msgRollOverDescription1'); ;
                break;
            }
            case $.cdh.enums.fundRolloverTypeEnum.cap: {
                result = $.cdh.languageConstant.getConstant('AccountDetail.msgRemaingBalUpto') + $.cdh.helper.htmlFormatAmount(amount);
                break;
            }
            case $.cdh.enums.fundRolloverTypeEnum.percentage: {
                result = rate + " % " + $.cdh.languageConstant.getConstant('AccountDetail.msgPercentOfRemainingBal');
                break;
            }
            case $.cdh.enums.fundRolloverTypeEnum.capAndPercentage: {
                result = rate + "% " + $.cdh.languageConstant.getConstant('AccountDetail.msgPercentOfRemainingBalUpto') + $.cdh.helper.htmlFormatAmount(amount);
                break;
            }
        }

        return result;
    };
}


