import template from './product-look-up.html';

(function ($, ko) {
    function ProductLookUp(params) {
        const self = this;
        self.storeProducts = ko.observableArray();
        self.productIds = ko.observableArray();
        self.productKeyword = ko.observable('')
        self.productSearchName = ko.observable('');
        self.productLookUpService = new $.cdh.http.smartAccount.ProductLookUpService;
        self.disableSearchButton = ko.observable(false);

        self.searchProducts = function (data, event) {
            self.productIds([]);
            self.productKeyword('');
            var isValid = !/^\d*$/.test(self.productSearchName().trim())
            if (isValid && !self.productSearchName().includes(',')) {
                self.productKeyword(self.productSearchName())
            } else {
                if (self.productSearchName().includes(',')) {
                    self.productIds(self.productSearchName().split(',').map(item => item.trim()).filter(value => value.length));
                } else {
                    self.productIds.push(self.productSearchName().trim())
                }
            }
            const payload = {
                "ProductIds": self.productIds(),
                "productsKeyword": self.productKeyword()
            }
           
            self.productLookUpService.ProductSearch(payload).then(function (result) {
                    self.storeProducts(result.xhr.responseJSON.Products);

            })
            .catch(function (data) {
                    self.storeProducts([]);

            })  
        };

        // Function to trigger on Enter key press
        self.searchOnEnter = function (data, event) {
            if (event.key === 'Enter' && self.disableSearchButton()) {
                self.searchProducts();  
                return false; 
            }
            return true;
        };


        document.getElementById('ProductId').addEventListener('keyup', function (newData) {
            if (newData.target.value.length > 0) {
                self.productSearchName(newData.target.value);
                self.disableSearchButton(true)
            } else {
                self.disableSearchButton(false)
            }
        })
    }

    self.onlyAlpaNumericAndComma = function (data, event) {
        const charCode = event.charCode;
        const char = String.fromCharCode(charCode);
        const isValid = /^[A-Za-z0-9,]$/.test(char);
        return isValid;
    };

    ko.components.register('product-look-up', {
        template: template, 
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ProductLookUp(params, componentInfo);
            }
        }
    });

    self.clickFindStores = function () {
        window.location.href = $.cdh.routes.predefined.ila.merchants
    };
    
})(jQuery, ko);